<template>
  <div
    class="toggle"
    @click="toggleScopeType"
  >
    <div class="toggle__label">
      <i class="label__icon icon-building" />
      <span class="label__text">{{
        scopeLabel ? scopeLabel : $t(`sharedAnalytics.scope.toggle.COMPANY`)
      }}</span>
    </div>
    <div
      v-if="!isCompanyOnly"
      class="toggle__label"
    >
      <i class="label__icon icon-person" />
      <span class="label__text">{{
        $t('sharedAnalytics.scope.toggle.USER_SCOPE')
      }}</span>
    </div>

    <div
      class="toggle__overlay"
      :class="`toggle__overlay--${scopeType}`"
    >
      <Transition
        name="fade"
        mode="out-in"
      >
        <div
          :key="scopeType"
          class="overlay__container"
        >
          <i
            class="container__icon"
            :class="`icon-${
              scopeType === ScopeType.USER_SCOPE && !isCompanyOnly
                ? 'person'
                : 'building'
            }`"
          />
          <span
            class="container__text"
            :title="scopeLabel"
          >
            {{
              isCompanyOnly
                ? scopeLabel ?? $t(`sharedAnalytics.scope.toggle.COMPANY`)
                : $t(`sharedAnalytics.scope.toggle.${scopeType}`)
            }}
          </span>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { ScopeType } from '~/types/permissions'
import { useScopeStore } from '~/stores/scope'

export default {
  name: 'Toggle',
  props: {
    isCompanyOnly: {
      type: Boolean,
      default: false
    },
    scopeLabel: {
      type: String,
      default: undefined
    }
  },
  setup() {
    return { ScopeType }
  },
  computed: {
    ...mapState(useScopeStore, ['scopeType'])
  },
  methods: {
    toggleScopeType() {
      if (this.isCompanyOnly) return
      const { toggleScopeType } = useScopeStore()
      toggleScopeType()
    }
  }
}
</script>

<style lang="scss" scoped>
$toggle-padding: 2px;
$toggle-height: 40px;
$toggle-radius: 8px;

.toggle {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: $toggle-padding;
  height: $toggle-height;
  background-color: $bg-secondary;
  border-radius: $toggle-radius;
  cursor: pointer;
  @include block-shadow;
  box-sizing: border-box;

  &__label {
    display: flex;
    align-items: center;
    padding: 0 $margin-mini;
    color: $text-tertiary;
    z-index: 0;
    overflow: hidden;

    .label {
      &__icon {
        @include font-icon;
        @include font-size(
          $font-size-intermediate,
          $toggle-height - 2 * $toggle-padding
        );
        margin-right: 4px;
      }

      &__text {
        @include font-text;
        @include font-size(
          $font-size-regular,
          $toggle-height - 2 * $toggle-padding
        );
        text-overflow: ellipsis;
        max-width: $scope-label-width;
      }
    }
  }

  &__overlay {
    position: absolute;
    height: 36px;
    padding: 0 $margin-mini;
    width: fit-content;
    background-color: $button-secondary;
    color: $text-inverse;
    border-radius: $toggle-radius - $toggle-padding;
    transition: $medium-transition cubic-bezier(0.77, 0, 0.175, 1);
    transition-property: left, transform;
    will-change: contents;
    z-index: 1;

    .overlay {
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;

        .container {
          &__icon {
            @include font-icon;
            @include font-size(
              $font-size-intermediate,
              $toggle-height - 2 * $toggle-padding
            );
            margin-right: 4px;
          }

          &__text {
            @include font-text;
            @include font-size(
              $font-size-regular,
              $toggle-height - 2 * $toggle-padding
            );
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: $scope-label-width;
          }
        }
      }
    }

    &--COMPANY {
      left: $toggle-padding;
      transform: translateX(0);
    }

    &--USER_SCOPE {
      left: calc(100% - #{$toggle-padding});
      transform: translateX(-100%);
    }
  }
}
</style>
